
    @media (min-width: 767px) {
        .disply{
            display: none !important;
        }
    }
    .hJfLQG{
        color: #175783 !important;
    }
    .css-12zruzg,.iiwcVG, .css-1w0agsq, .css-1jj1zgp, .css-so7oqs, .kwqHBg, .css-nkpdeu{
        color: #175783 !important;
    }
    .bummkT, .dgNdzX button, .css-p2f5xy, .css-pjcxo7{
        background-color: #175783 !important;
    }
    .cdjlFJ{
        top:-20px !important;
    }
    .css-1ibe987 {
        max-width: 28rem !important;
      }
      .css-1t414hz{
        margin-right: 2rem !important;
      }
      .css-1t414hz {
        margin-left: 2rem !important;
      }
     
      @media screen and (min-width: 600px) {
        .css-12lyqmp {
          width: 52% !important;
        }
      }
      @media (min-width:640px)
      {
        .eBqbov{
          width:10rem !important;
        }
        .jvlimc {
            width: 10rem !important;
        }
     }